import React from 'react';
import { formatDistanceToNow, parseISO, isBefore, isAfter, subYears } from 'date-fns';
import '../Gifting_page.css';

class Timeline extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            yearsShown: 1, // Start by showing 1 year back
        }

        this.getOrdinalSuffix = this.getOrdinalSuffix.bind(this);
        this.showMoreYears = this.showMoreYears.bind(this);
    }

    getOrdinalSuffix (number) {
        const remainder = number % 100;
        if (remainder >= 11 && remainder <= 13) {
          return 'th';
        }
        
        switch (number % 10) {
          case 1:
            return 'st';
          case 2:
            return 'nd';
          case 3:
            return 'rd';
          default:
            return 'th';
        }
      };
      

    showMoreYears() {
        /*
        this.setState((prevState) => ({
            yearsShown: prevState.yearsShown + 1,
        }));
        */

        ////

        const { milestones, gifts_activity } = this.props;
        const currentDate = new Date();
        const { yearsShown } = this.state;

        // Combine milestones and activity
        const activities_and_milestones = milestones.concat(gifts_activity);

        // Sort milestones by date (ascending)
        const sortedMilestones = activities_and_milestones.sort((a, b) => new Date(b.date) - new Date(a.date));

        // Filter for past milestones and activities
        const pastMilestones = sortedMilestones.filter(milestone => isBefore(parseISO(milestone.date), currentDate));

        console.log('past milestones:', pastMilestones)

        // Find the next milestone that isn't shown yet
        const nextMilestone = pastMilestones.find(milestone =>    
            isBefore(parseISO(milestone.date), subYears(currentDate, yearsShown))
        );
        console.log(nextMilestone? nextMilestone : 'no next milestone');

        if (nextMilestone) {
            // Calculate how many years back the next milestone is
            const nextMilestoneYear = new Date(nextMilestone.date).getFullYear();
            const currentYear = currentDate.getFullYear();
            const additionalYears = currentYear - nextMilestoneYear;

            // Increase yearsShown by the needed number of years
            this.setState({
                yearsShown: yearsShown + additionalYears,
            });
        } else {
            // No more milestones to show, add one year
            this.setState({
                yearsShown: yearsShown + 1,
            });
        }

    }


   

    render () {

        const { milestones, account_name, gifts_activity } = this.props;
        const currentDate = new Date();

        const { yearsShown } = this.state;

        // mix past milestones with gifts activity
        const activities_and_milestones = milestones.concat(gifts_activity);
        // Sort milestones by date
        const sortedMilestones = activities_and_milestones.sort((a, b) => new Date(a.date) - new Date(b.date));
        const reverseSort = milestones.sort((a,b) => new Date(b.date) - new Date(a.date))
        // Filter upcoming and past milestones
        const upcomingMilestones = reverseSort.filter(milestone => isAfter(parseISO(milestone.date), currentDate));
        const pastMilestones = sortedMilestones.filter(milestone => isBefore(parseISO(milestone.date), currentDate));

         // Filter past milestones to only show events within the yearsShown range
         const filteredPastMilestones = pastMilestones.filter(milestone => 
            isAfter(parseISO(milestone.date), subYears(currentDate, yearsShown))
        );

        
        return (
            <div className={`gifting_page_card ${this.props.mobile ? ' mobile_only' : ' desktop_only'}` }>

                                
            <h2>Milestones & Gifting Activity</h2>
                

            {upcomingMilestones.length > 0 ? 
            (<p>Upcoming Milestones:</p>) 
            : null}

                <div>
                    {upcomingMilestones.length > 0 ? (
                        upcomingMilestones.map((milestone, index) => (
                            <div key={index} className='timeline_item'>
                                <div className='timeline_item_icon'>
                                    <i className='material-icons-outlined'>
                                        {milestone.type === 'school' ? 'school' : 'cake'}
                                    </i>
                                </div>
                                <div className='timeline_item_details'>
                                    <p className=''>
                                    {milestone.type === 'birthday' && milestone.birthdayNumber === 0
                                            ? `${account_name}'s due date!`
                                            : milestone.type === 'birthday'
                                            ? `${account_name}'s ${milestone.birthdayNumber}${this.getOrdinalSuffix(milestone.birthdayNumber)} Birthday!`
                                            : `${account_name} is starting ${milestone.milestone.toLowerCase()}!`}
                                    </p>
                                    <p className='tiny_text'>In {formatDistanceToNow(parseISO(milestone.date), { addSuffix: false })}</p>
                                </div>
                            </div>
                        ))
                    ) 
                    : (
                        null
                    )}
                </div>

                {filteredPastMilestones.length > 0 ? (
                    <p>Recent Activity:</p>
                ) : null }
                <div>
                    {filteredPastMilestones.length > 0 ? (
                        filteredPastMilestones.reverse().map((milestone, index) => {
                            let milestoneDate = new Date(parseISO(milestone.date)).toLocaleString('en-US', { timeZone: 'America/New_York' });
                            return( <div key={index} className='timeline_item'>
                                <div className='timeline_item_icon'>
                                    <i className='material-icons-outlined'>
                                        {milestone.type === 'school' ? 'school' 
                                        : milestone.type === 'birthday' ? 'cake'
                                        : milestone.type === 'gift' ? 'attach_money' : null}
                                    </i>
                                </div>
                                <div className='timeline_item_details'>
                                    <p className=''>
                                        {   milestone.type === 'birthday' && milestone.birthdayNumber === 0 ? 
                                            `${account_name} was born!`
                                            : milestone.type === 'birthday' ? 
                                            `${account_name}'s ${milestone.birthdayNumber}${this.getOrdinalSuffix(milestone.birthdayNumber)} Birthday!`
                                            : milestone.type === 'school' ?
                                                `${account_name} started ${milestone.milestone.toLowerCase()}!` 
                                            : milestone.type === 'gift' ? 
                                            `${account_name} received a gift on Greatest Gift!`
                                            : null 
                                        }

                                    </p>
                                    <p className='tiny_text'>{formatDistanceToNow(new Date(milestoneDate), { addSuffix: true })}</p>
                                    </div>
                            </div>
                            )}
                        )
                    ) : (
                       null
                    )}
                </div>
                
               
                {/* Show More button */}
                {filteredPastMilestones.length < pastMilestones.length ? (
                    <p onClick={this.showMoreYears} className='cursor green_text bold align_center'>Show More</p>
                ) : null}


            
        </div>
        );
    }
}

export default Timeline;