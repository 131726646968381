import React from 'react';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format } from 'date-fns';

import './Add_step_1.css';
import Overlay_popup from '../../../general_components/Overlay_popup/Overlay_popup';
import { CalendarContainer } from 'react-datepicker';

class Add_step_1 extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            open_overlay_1: false,
            open_overlay_2: false,

            date: '',
            formatted_date: ''
        };

    }

    CalContainer = ({ className, children }) => {
        return (
          <div style={{ padding: "12px", background: "#16dfad", color: "#fff", "border-radius": '5px', "z-index": 10 }}>
            <CalendarContainer className={className}>
              <div style={{ position: "relative"}}>{children}</div>
            </CalendarContainer>
          </div>
        );
    }

    render () {
        if (this.props.currentStep !== 1) {
            return null;
        }
        
        return (
           <div>
                <h2 className='center'>Let's add a gift recipient!</h2>
                <form>
                    <div className='headline_with_icon'>
                        <p className='form_label'>What is the recipient's name? </p>
                        <i onClick={() => this.setState({open_overlay_1: true})} className='material-icons-outlined inline_icon'>help</i>
                    </div>

                    <input 
                        type='text' 
                        id='account_name' 
                        onChange={this.props.handleChange}
                        name='account_name' 
                        onBlur={(event) => {this.props.checkUnique(event, 'account_name')}}
                        placeholder='Name' 
                        value={this.props.account_name}
                        autoFocus
                        maxLength='50'
                        autoComplete='off'
                        />

                    <div className='headline_with_icon'>
                        <p className='form_label'>Recipient's date of birth (or due date): </p>
                        <i onClick={() => this.setState({open_overlay_2: true})} className='material-icons-outlined inline_icon'>help</i>
                    </div>

                    {/*
                    <input 
                        type='text' 
                        id='birthday' 
                        onChange={this.props.handleDateChange}
                        value={this.props.birthday}
                        name='birthday' 
                        maxLength='10'
                        minLength='10'
                        placeholder='MM / DD / YYYY'
                         />
                         */
                         }
                    
                    <DatePicker 
                        selected={this.state.date} 
                        onChange={(date) => {
                            this.setState({date: date});
                            date ? 
                            this.props.handleDateChange(format(date, 'MM/dd/yyyy'))
                            : 
                            this.setState({formatted_date: ""})
                            }
                        } 

                        showIcon
                        toggleCalendarOnIconClick

                        showYearDropdown
                        scrollableYearDropdown
                        yearDropdownItemNumber={25}

                        placeholderText="MM / DD / YYYY"
                    />

                
                </form>

                <Overlay_popup 
                    open            = {this.state.open_overlay_1}
                    closer          = {true}
                    handleClose     = {() => {
                        this.setState({open_overlay_1: false})
                    }
                    }
                    overlay_title   = 'Quick Tips'
                    body_1          = 'The recipient could be your child, yourself, or a different beneficiary you are receiving gifts for. You can change this name later.'
                />

                <Overlay_popup 
                    open            = {this.state.open_overlay_2}
                    closer          = {true}
                    handleClose     = {() => {
                        this.setState({open_overlay_2: false})
                    }
                    }
                    overlay_title   = 'Quick Tips'
                    body_1          = {`We show potential gift givers upcoming milestones like due dates and upcoming birthdays based on the date of birth. You can choose to hide these milestones later on.
                        We also use this information to calculate savings potential, tailor guides based on receiver age, and send birthday reminders. 
                    Celebrating a birthday? Enter the recipient's date of birth, we'll calculate the age automatically.
                    If you or a loved one are expecting a baby, enter the estimated due date for the best experience. You can change this date later on.`}
                />
                

           </div>
        );
    }
}

export default Add_step_1;