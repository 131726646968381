import React from 'react';
import List_Item from '../List_Item/List_Item.js';  

class List_w_Category extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        categories: []
    }
  }
  
  render() {
        return this.props.list.map(list_entry => {
            let verified = list_entry.disabled;
            return (
                
                <li className={'pm_category_item'} key={list_entry.id}>
                
                    <div className={this.props.dd_style ? 'ddc_category_header' : 'pm_category_header'}>
                        <div className={this.props.dd_style ? 'ddc_category_header_main_text' : 'pm_category_header_main_text'}>
                            <p className={this.props.dd_style ? 'tiny_text' : 'bold'}>
                              {list_entry.category_header}
                            </p>
                        </div>
                    </div>
                
                    <ul className='pm_item_list'>
                    
                      <List_Item 
                        items={list_entry.items} 
                        verified={verified} 
                        button_active={verified}
                        onButtonClick={this.props.onVerifyClick} 
                        button_text={this.props.button_text}
                      />
                        
                    </ul>

                </li>
            );
          });
  }
}
      
export default List_w_Category;
      
